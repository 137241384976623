import { USER_TYPE, getProfile } from "@/api/user";
import _ from "lodash";
const { dealer } = USER_TYPE;

export default {
  namespaced: true,
  state: {
    closedVideos: [],
    user: null,
    notification: null,
    notifications: [],
    bonuses: null,
    accentColor: "",
    inFavorites: null,
    languages: [
      {
        code: "ua",
        label: "Українська",
        slug: "Укр",
      },
      {
        code: "ru",
        label: "Русский",
        slug: "Рус",
      },
    ],
    permissions: [],
    activeWizard: null,
  },

  getters: {
    userId(state) {
      return state.user?.id;
    },

    activeWizardKey(state) {
      return state.activeWizard;
    },

    getPermission(state) {
      return _.mapValues(_.keyBy(state.permissions, "nameOriginal"), "isActive");
    },

    getWizards(state) {
      return state.user?.wizards;
    },

    newNotification(state) {
      return state.notification;
    },

    userNotifications(state) {
      return state.notifications;
    },

    userProfile(state) {
      return state.user;
    },

    userBonuses(state) {
      return state.bonuses;
    },

    userProfileName(state) {
      const firstName = state.user.firstName;
      const lastName = state.user.lastName;

      return `${firstName} ${lastName}` || "";
    },

    userProfileEmail(state) {
      return state.user.email;
    },

    userPhone(state) {
      return state.user?.phone;
    },

    userAvatar(state) {
      return state.user.avatar;
    },

    userManager(state) {
      return state.user.detail?.company?.oneCEmployee;
    },

    languages(state) {
      return state.languages;
    },

    backgroundImage(state) {
      return state.user?.imageName || "";
    },

    balance(state) {
      return state.user.balance;
    },

    isUserDealer(state) {
      return state.user.type === dealer;
    },

    isUserDealerAdmin(state) {
      return state.user.roles[0].code === "dealerAdmin";
    },
  },

  mutations: {
    SET_PROFILE(state, user = {}) {
      state.user = user;
      state.permissions = user.permissions;
      state.inFavorites = state.user.favoriteProductsCount;
      state.closedVideos = user.closedVideos;
      state.bonuses = user.bonusProgram;
    },

    SAVE_USER_DEALER_STATE(state, userType) {
      window.localStorage.setItem("isUserDealer", Number(userType === dealer));
    },

    SET_IN_FAVORITES(state, count) {
      state.inFavorites = count;
    },

    SET_CLOSED_VIDEOS(state, type) {
      state.closedVideos.push(type);
    },

    SET_NEW_NOTIFICATION(state, notification) {
      state.notification = notification;
    },

    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },

    SET_BONUSES(state, bonuses) {
      state.bonuses = bonuses;
    },

    SET_ACTIVE_WIZARD(state, key) {
      state.activeWizard = key;
    },
  },

  actions: {
    async getProfile(context) {
      const response = await getProfile();

      window.localStorage.setItem("permissions", JSON.stringify(response.permissions));

      context.commit("SET_PROFILE", response);
    },

    removeProfile(context) {
      context.commit("SET_PROFILE");
    },

    setInFavoritesCount(context, count) {
      context.commit("SET_IN_FAVORITES", count);
    },

    setClosedVideos(context, type) {
      context.commit("SET_CLOSED_VIDEOS", type);
    },

    setNewNotification(context, notification) {
      context.commit("SET_NEW_NOTIFICATION", notification);
    },

    setNotifications(context, notifications) {
      context.commit("SET_NOTIFICATIONS", notifications);
    },

    setBonuses(context, bonuses) {
      context.commit("SET_BONUSES", bonuses);
    },
  },
};
