<template>
  <div class="form">
    <OurGroups>
      <OurGroup class="form-group">
        <OurInput
          v-model="form['companies.id']"
          :label="$t('label.id')"
          :placeholder="$t('placeholder.caseNumber')"
        />

        <PaginationSelect
          v-model="form['companies.name']"
          :label="$t('label.title')"
          :placeholder="$t('placeholder.title')"
          :options="companies"
          value-key="id"
          :options-limit="companies?.length"
        />

        <OurSelect
          v-model="form.oneCEmployeeId"
          :label="$t('label.manager')"
          :placeholder="$t('placeholder.manager')"
          :options="managers"
          item-label="label"
          value-key="id"
        />

        <OurSelect
          v-model="form.status"
          :label="$t('label.status')"
          :placeholder="$t('placeholder.selectStatus')"
          :options="getStatuses"
          item-label="text"
          value-key="value"
        />

        <PaginationSelect
          v-model="form.customerId"
          :options="customers"
          :options-limit="customers?.length"
          :label="$t('label.customer')"
          :placeholder="$t('placeholder.selectCustomer')"
        />

        <OurInput
          v-model="form.taxNumber"
          :label="$t('label.taxNumber')"
          :placeholder="$t('placeholder.taxNumber')"
          :error="taxNumberError"
        />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import PaginationSelect from "@/ui/select/PaginationSelect.vue";
import { mapGetters, mapState } from "vuex";
import ValidationService from "@/services/validation.service";
import { maxLength, integer } from "vuelidate/lib/validators";

const { TAX_NUMBER_MAX_LENGTH } = ValidationService;

export default {
  name: "FilterForm",

  components: {
    PaginationSelect,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        "companies.id": "",
        "companies.name": "",
        oneCEmployeeId: "",
        status: "",
        taxNumber: "",
        customerId: "",
      },
      isValid: false,
    };
  },

  validations: {
    form: {
      taxNumber: { integer, maxLength: maxLength(TAX_NUMBER_MAX_LENGTH) },
    },
  },

  computed: {
    ...mapState("dealerCompany", ["managers", "companies"]),
    ...mapGetters("dealerCompany", ["getStatuses"]),
    ...mapState("dealerUsers", ["customers"]),

    taxNumberError() {
      const maxLength = this.$v.form.taxNumber.maxLength;
      const number = this.$v.form.taxNumber.integer;

      let error = "";

      if (!maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TAX_NUMBER_MAX_LENGTH,
        });
      } else if (!number) {
        error = this.$t("validation.taxNumber");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
    },
  },

  mounted() {
    this.setForm();
  },

  methods: {
    setForm() {
      this.form = this.value;

      this.companies.find((company) => {
        if (company.label === this.form["companies.name"]) {
          this.form["companies.name"] = company.id;
        }
      });
    },

    onChangeForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>
