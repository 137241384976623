import ApiService from "@/services/api.service";

const CART_VALUES = {
  storage: "fromStorage",
  object: "toObject",
  post: "fromPost",
  card: "card",
  account: "account",
  other: "other",
};

async function fullTextSearch(searchData) {
  const { search, page, perPage, name, categoryId, status, checkbox, range, storages, dates } =
    searchData;

  let resource = `/products?pagination[page]=${page}&pagination[perPage]=${perPage}`;

  if (name) resource += `&filters[name]=${name}`;
  if (categoryId) resource += `&filters[categoryId]=${categoryId}`;
  if (status.length) resource += `&filters[status]=${status}`;
  if (search) resource += `&search=${search}`;
  if (storages?.length) resource += `&filters[storages]=${storages}`;

  if (checkbox && Object.keys(checkbox).length) {
    for (let key in checkbox) {
      if (checkbox[key].length) {
        resource += `&filters[properties][${key}]=${encodeURIComponent(checkbox[key].join(","))}`;
      }
    }
  }

  if (range && Object.keys(range).length) {
    for (let key in range) {
      if (range[key].startRange || range[key].endRange) {
        resource += `&filters[properties][${key}][]=${range[key].startRange}`;
        resource += `&filters[properties][${key}][]=${range[key].endRange}`;
      }
    }
  }

  if (dates && dates.length) {
    for (const key in dates) {
      resource += `&filters[receipt_date][]=${dates[key]}`;
    }
  }

  const response = await ApiService.get(resource);

  return response.data.data;
}

//UPDATED
async function fetchCatalogProducts(params) {
  try {
    const { data } = await ApiService.get("/products", {
      params: { ...params },
    });

    return { products: data.data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
//UPDATED

async function getProductsFilters() {
  let resource = "/products/filters";

  const response = await ApiService.get(resource);

  return response.data;
}

export { CART_VALUES, fullTextSearch, getProductsFilters, fetchCatalogProducts };
