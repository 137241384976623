<template>
  <div class="form">
    <OurGroups>
      <OurGroup class="form-group">
        <PaginationSelect
          v-model="form.id"
          :options="customers"
          :options-limit="customers?.length"
          :label="$t('label.customer')"
          :placeholder="$t('placeholder.selectCustomer')"
        />

        <OurInput
          v-model="form.name"
          :label="$t('label.title')"
          :placeholder="$t('placeholder.title')"
        />

        <OurSelect
          v-model="form.accessGroupId"
          :label="$t('label.accessGroup')"
          :placeholder="$t('placeholder.accessGroup')"
          :options="accessGroups"
          item-label="label"
          value-key="id"
        />

        <OurSelect
          v-model="form.status"
          :label="$t('label.status')"
          :placeholder="$t('placeholder.selectStatus')"
          :options="getStatuses"
          item-label="text"
          value-key="value"
        />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import PaginationSelect from "@/ui/select/PaginationSelect.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "FilterForm",

  components: {
    PaginationSelect,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        id: "",
        name: "",
        accessGroupId: "",
        status: "",
      },
    };
  },

  computed: {
    ...mapState("dealerCompany", ["accessGroups"]),
    ...mapState("dealerUsers", ["customers"]),
    ...mapGetters("customers", ["getStatuses"]),
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
    },
  },

  mounted() {
    this.setForm();
  },

  methods: {
    setForm() {
      this.form = this.value;
    },

    onChangeForm() {
      this.$emit("input", this.form);
    },
  },
};
</script>
